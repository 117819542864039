<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0">
                <i class="fas fa-user-circle"></i> ข้อมูลใบสั่งซื้อ
              </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="pb-4 text-right no_print">
            <button
              type="button"
              class="btn btn-warning style_btn"
              @click="clickPrint()"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
          <div>
            <div class="card custom-card">
              <div class="card-body section-to-print" id="view_bill_order">
                <div class="row">
                  <div class="col-md-6">
                    <address>
                      <strong>{{
                        item.supplier ? item.supplier.tax_address : ""
                      }}</strong
                      ><br />
                      เลขประจำตัวผู้เสียภาษี :
                      {{
                        item.supplier
                          ? item.supplier.tax_id
                            ? item.supplier.tax_id
                            : "-"
                          : "-"
                      }}<br />
                      เบอร์โทรศัพท์ :
                      {{
                        item.supplier
                          ? item.supplier.phone
                            ? item.supplier.phone
                            : "-"
                          : "-"
                      }}
                    </address>
                  </div>
                  <div class="col-md-6 text-right">
                    <h2>ใบสั่งซื้อ</h2>
                  </div>
                  <div class="col-md-6 pt-6">
                    <address>
                      <p>ลูกค้า</p>
                      <strong>{{ item.customer_name }}</strong
                      ><br />
                      {{ item.delivery_address }}<br />
                      เลขประจำตัวผู้เสียภาษี :
                      {{ item.customer.tax_id ? item.customer.tax_id : "-"
                      }}<br />
                      เบอร์โทรศัพท์ : {{ item.customer.phone }}<br />
                      อีเมล : {{ item.customer.email }}
                    </address>
                  </div>

                  <div class="col-md-6 pt-6">
                    <address style="margin-left: 70%">
                      รหัส : {{ item.code }}<br />
                      ผู้ขาย : {{ item.sale.fullname }}<br />
                      วันที่ : {{ item.date_order_short }}<br />
                      <!-- วันที่จัดส่ง : {{item.expected_delivery_date}} {{item.expected_delivery_time}} -->
                    </address>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive padding_top_20">
                      <table class="table align-middle table-bordered">
                        <thead>
                          <tr class="text-center">
                            <th>ลำดับ</th>
                            <th>รหัสสินค้า</th>
                            <th>รายการ</th>
                            <th>ราคาขาย</th>
                            <th>ราคารวม</th>
                            <th>อัพเซลล์</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(data, index) in item.products">
                            <tr :key="`list_order_view_${index}`">
                              <td
                                :class="`${
                                  data.parent !== data.child
                                    ? 'text-center'
                                    : 'text-right'
                                }`"
                                :rowspan="data.products.length + 1"
                                style="vertical-align: top"
                              >
                                {{ index + 1 }}
                              </td>
                              <td class="text-center">
                                {{ data.code }}
                              </td>
                              <td class="text-center">
                                {{ data.product_name }}
                              </td>
                              <td class="text-right">
                                {{ data.price | (number - decimal - format) }}
                              </td>
                              <td class="text-right">
                                {{ data.total | (number - decimal - format) }}
                              </td>
                              <td class="text-center">
                                {{ getLabelUpsale(data.upsale) }}
                              </td>
                            </tr>
                            <tr
                              v-for="(d, i) in data.products"
                              :key="`list_order_view_sub_${i}`"
                            >
                              <td class="text-center">
                                {{ d.code }}
                              </td>
                              <td class="text-center">{{ d.product_name }}</td>
                              <td class="text-right">
                                {{ d.price | (number - decimal - format) }}
                              </td>
                              <td class="text-right">
                                {{ d.total | (number - decimal - format) }}
                              </td>
                              <td class="text-center">
                                {{ getLabelUpsale(d.upsale) }}
                              </td>
                            </tr>
                          </template>
                          <tr
                            v-if="item.products.length === 0"
                            class="text-center"
                          >
                            <td colspan="5">ไม่มีข้อมูล</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="col-12 text-right">
                        <p>
                          รวม : {{ item.total | (number - decimal - format) }}
                        </p>
                        <p>
                          ค่าส่ง :
                          {{ item.service | (number - decimal - format) }}
                        </p>
                        <p>
                          รวมทั้งสิ้น :
                          {{ item.total | (number - decimal - format) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="float-right no_print">
              <span
                ><router-link :to="'/delivery'" class="nav-link text-underline">
                  กลับ</router-link
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import DateUtils from "../../../util/dateUtils";

export default {
  name: "delivery-view-order-page",
  data() {
    return {
      permission: {
        appSlug: "order",
        actionData: {},
      },
      id: "",
      bill: {},
      item: {
        customer: {},
        supplier: {},
        sale: {},
        products: [],
      },
      order_detail: {},
    };
  },
  methods: {
    async getOrderBill() {
      const result = await this.HttpServices.getData(`/ab_order/${this.id}`);
      if (result && result.status.code == "200") {
        this.item = result.data;
        if (this.item.expected_delivery_date) {
          this.item.expected_delivery_date = DateUtils.dateFormat(
            this.item.expected_delivery_date,
            "DD/MM/YYYY"
          );
        }
        this.item.products.forEach((v) => {
          let total = v.price;
          v.products.forEach((vv) => {
            total += vv.price;
          });
          v.total = total;
        });
      } else {
        this.AlertUtils.alertCallback("warning", `ไม่พบข้อมูล Order`, () => {
          this.$router.push("/delivery");
        });
      }
    },
    getLabelUpsale(upsale) {
      if (upsale) {
        return "ใช่";
      }
      return "ไม่ใช่";
    },
    clickPrint() {
      window.print();
    },
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    $(document).ready(function () {
      $(".printMe").click(function () {
        $("#view_bill_order").print();
      });
    });
    this.order_detail = await this.SessionStorageUtils.getSession(
      "page-delivery"
    );
    this.order_detail = this.order_detail ? JSON.parse(this.order_detail) : {}
    this.id = this.order_detail.order_id || JSON.parse(this.order_detail).order_id;
    this.getOrderBill();
  },
};
</script>

<style scoped>
.table-plain tbody tr,
.table-plain tbody tr:hover,
.table-plain tbody td {
  background-color: transparent;
  border: none;
}
</style>
